import { memoize, strategies } from "@formatjs/fast-memoize";
import type { Cache } from "@formatjs/fast-memoize";
import ICU from "i18next-icu";
import type { IcuConfig, IcuFormats } from "i18next-icu";
import IntlMessageFormat from "intl-messageformat";
import type { Formats, FormatterCache, Formatters } from "intl-messageformat";

// eslint-disable-next-line local-eslint-rules/no-direct-dashboard-config-access
import config from "@lib/dashboardConfig";

import * as utils from "./utils";

/**
 * The current active time zone, as set in company preferences
 */
const activeTimezone = { current: config?.user.company.iana_time_zone };

// copy/pasted from createFastMemoizeCache in https://github.com/formatjs/formatjs/blob/main/packages/intl-messageformat/src/core.ts
function createFastMemoizeCache<V>(store: Record<string, V | undefined>): Cache<string, V> {
  return {
    create() {
      return {
        get(key) {
          return store[key];
        },
        set(key, value) {
          store[key] = value;
        },
      };
    },
  };
}

// copy/pasted from createdDefaultFormatters in https://github.com/formatjs/formatjs/blob/main/packages/intl-messageformat/src/core.ts
function createBrazeFormatters(
  cache: FormatterCache = {
    number: {},
    dateTime: {},
    pluralRules: {},
  }
): Formatters {
  return {
    getNumberFormat: memoize((...args) => new Intl.NumberFormat(...args), {
      cache: createFastMemoizeCache(cache.pluralRules),
      strategy: strategies.variadic,
    }),
    // Braze-specific formatting here
    getDateTimeFormat: memoize(
      (...args) => {
        if (args[1]?.scale) {
          return new Proxy(new Intl.DateTimeFormat(...args), {
            get(dateTimeFormat, field, receiver) {
              if (field === "format") {
                return new Proxy(dateTimeFormat.format, {
                  apply(format, _this, [dateArg]) {
                    const timestampInMilliseconds =
                      typeof dateArg === "string"
                        ? // ISO string
                          /**
                           * @deprecated TODO: remove Date object and replace with new date/time utils. For details, see https://confluence.braze.com/display/ENG/Date+and+time+handling
                           */
                          new Date(dateArg).getTime()
                        : // timestamp in seconds
                          typeof dateArg === "number"
                          ? dateArg * args[1].scale
                          : // date object
                            dateArg.getTime();
                    return format.call(_this, timestampInMilliseconds).replace(" ", " "); // replace weird non-breaking spaces with a space;
                  },
                });
              }
              return Reflect.get(dateTimeFormat, field, receiver);
            },
          });
        }
        return new Intl.DateTimeFormat(...args);
      },
      {
        cache: createFastMemoizeCache(cache.dateTime),
        strategy: strategies.variadic,
      }
    ),
    getPluralRules: memoize((...args) => new Intl.PluralRules(...args), {
      cache: createFastMemoizeCache(cache.pluralRules),
      strategy: strategies.variadic,
    }),
  };
}

/**
 * The Braze-specific additions to date formatting allowing formatting a timestamp
 *
 * At Braze, we represent time in 2 distinct ways:
 *
 * 1. a single point in time
 * 2. a single point in time **in a message recipient's local time zone**
 *
 * The first time value is displayed in our dashboard using the active time zone, which is a setting at the company level called "company time zone"
 * The second time value is displayed in our dashboard with the caveat "in the recipient's local time" and actually represents multiple points in time
 * depending on the number of time zones the message recipients occupy.
 *
 * Under the hood, both values are represented using a timestamp defined as "the number of seconds since 1970 in UTC time zone". In order to ensure the
 * value displayed in our dashboard is correct, we have to ensure that it is actually the number of seconds since 1970 in the active time zone.
 *
 * As an example, "March 9, 2023 at 10:24 AM" in UTC is 1678357440 seconds since 1970 in UTC, but "March 9, 2023 at 10:24 AM" in EST is 1678375440 seconds
 * since 1970 in UTC. Thus, if we are trying to represent a timestamp since 1970 UTC that is actually a time in EST, we have to use the time zone to
 * correctly format the time.
 *
 * In a web browser, there is only known time zone: the computer's time zone. Pass a timestamp to a `new Date()` and it will format textual representations
 * of that timestamp in the computer's time zone. Fortunately, this can be mediated by passing the `timeZone` option to `Intl.DateTimeFormat`'s constructor
 * options, and under the hood it will do the arithmetic to adjust the timestamp to the correct number of seconds since 1970 before attempting to render
 * the value in text form.
 *
 * Additionally, the `Date` object represents timestamps in a different format, as "milliseconds since 1970 in UTC time zone" rather than seconds. Thus,
 * we need to scale the timestamp up by 1000 to get the value that will work for formatting in the browser.
 *
 * Finally, to represent the 2nd option (a point in time in the recipient's local time zone), Braze has elected to represent these times as if they were
 * in the UTC time zone. To handle these edge cases, the `recipient-local-*` helpers are provided
 */
const setTimezoneInFormats = () => {
  IntlMessageFormat.formats = {
    ...IntlMessageFormat.formats,
    date: {
      ...IntlMessageFormat.formats.date,
      "date-from-timestamp": {
        scale: 1000,
        month: "long",
        year: "numeric",
        day: "numeric",
        timeZone: activeTimezone.current,
      },
      "datetime-from-timestamp": {
        scale: 1000,
        month: "long",
        year: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZone: activeTimezone.current,
      },
      "date-from-iso-string": {
        scale: 1000,
        month: "long",
        year: "numeric",
        day: "numeric",
        timeZone: activeTimezone.current,
      },
      "datetime-from-iso-string": {
        scale: 1000,
        month: "long",
        year: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZone: activeTimezone.current,
      },
      "recipient-local-date-from-timestamp": {
        scale: 1000,
        month: "long",
        year: "numeric",
        day: "numeric",
        timeZone: "UTC",
      },
      "recipient-local-datetime-from-timestamp": {
        scale: 1000,
        month: "long",
        year: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC",
      },
      "recipient-local-date-from-iso-string": {
        scale: 1000,
        month: "long",
        year: "numeric",
        day: "numeric",
        timeZone: "UTC",
      },
      "recipient-local-datetime-from-iso-string": {
        scale: 1000,
        month: "long",
        year: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC",
      },
    } as unknown as Formats["date"],
    time: {
      ...IntlMessageFormat.formats.time,
      "time-from-timestamp": {
        scale: 1000,
        hour: "numeric",
        minute: "numeric",
        timeZone: activeTimezone.current,
      },
      "recipient-local-time-from-timestamp": {
        scale: 1000,
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC",
      },
      "time-from-iso-string": {
        scale: 1000,
        hour: "numeric",
        minute: "numeric",
        timeZone: activeTimezone.current,
      },
      "recipient-local-time-from-iso-string": {
        scale: 1000,
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC",
      },
    } as unknown as Formats["time"],
  };
};
setTimezoneInFormats();

/**
 * An extension of the ICU class to support Braze-specific timestamp formatters
 */
export class BrazeICU extends ICU {
  static activeICU: Set<BrazeICU> = new Set();
  public formats: IcuFormats;
  public options: IcuConfig;
  public mem: any;

  static setActiveTimezone(tz?: string) {
    if (tz) {
      activeTimezone.current = tz;
      setTimezoneInFormats();
      BrazeICU.activeICU.forEach((icu) => {
        // when the active time zone is changed, clear the translation caches so that
        // translations will be regenerated
        icu.clearCache();
      });
    }
  }

  constructor(config?: IcuConfig) {
    super(config);
    this.mem = {};
    BrazeICU.activeICU.add(this);
  }

  /**
   * This is a straight copy/paste of the source of ICU.parse, but adding the `formatters` option to the instantiation of `IntlMessageFormat`
   */
  parse(res, options, lng, ns, key, info) {
    const hadSuccessfulLookup = info && info.resolved && info.resolved.res;
    const memKey = this.options.memoize && `${lng}.${ns}.${key.replace(/\./g, "###")}`;

    let fc;
    if (this.options.memoize) {
      fc = utils.getPath(this.mem, memKey);
    }

    try {
      if (!fc) {
        // without ignoreTag, react-i18next <Trans> translations with <0></0> placeholders
        // will fail to parse, as IntlMessageFormat expects them to be defined in the
        // options passed to fc.format() as { 0: (children) => string }
        // but the replacement of placeholders is done in react-i18next
        fc = new IntlMessageFormat(res, lng, this.formats as Partial<Formats>, {
          ignoreTag: true,
          // use Braze-specific formatters
          formatters: createBrazeFormatters(),
        });
        if (this.options.memoize && (this.options.memoizeFallback || !info || hadSuccessfulLookup)) {
          utils.setPath(this.mem, memKey, fc);
        }
      }
      return fc.format(options);
    } catch (err) {
      // if translations are not working, enable this console.log() to debug because the error does not display otherwise
      // console.log(err);
      return this.options.parseErrorHandler(err, key, res, options);
    }
  }
}
